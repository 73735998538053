import {styled} from 'styled-components';
import {default as data} from '../data/product';
import { lazy, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const S = {
  Product : styled.section`
    width : 100vw;
    height : calc(100vh - 100px);

    background : linear-gradient(180deg, #ffffff1a, #4788c81a);

    display : flex;

    @media(max-width : 1024px) {
      flex-direction : column;
    }
    
  `,
 
  Content : styled.section`
    width : 70%;
    height : calc(100% - 100px);
    position : absolute;
    right : 0;
    top : 100px;
    background : #ffffff;
    border-radius : 100px 0 0 0;
    overflow-y : auto;
    filter : drop-shadow(0 0 30px #4788c826);
    z-index : 3;

    @media(max-width : 1024px) {
      width : 100vw;
      height : 70%;
      bottom : 0;
      top : auto;

    }
  `
}

  const Menu = lazy(() => import('./Product/Menu'));
  const Main = lazy(() => import('./Product/Main'))
  const Feature = lazy(() => import('./Product/Feature'));
  const Spec = lazy(() => import('./Product/Spec'));

const Product = () => {
  const {state} = useLocation();
  const [select , setSelect] = useState(state);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [])

  useEffect(() => {
    if (state === null) {
      setSelect('Server')
    }
  }, [state])

  const contentRef = useRef();

  useEffect(() => {
    contentRef.current.scrollTo({
      top : 0,
      behavior : "smooth",
    })
  }, [select])

  return (
    <S.Product>
      <Menu state={select} setMenu={(menu) => setSelect(menu)}/>
      <S.Content ref={contentRef} >
        <Main data={data[select]}/>
        <Feature data={data[select]}/>
        <Spec data={data[select]}/>
      </S.Content>
    </S.Product>
  )
}

export default Product;