import { JLC1, JLC1000_1, JLC1000_2, JLC2, JLC3, JLC4, JLC5, JLS1, JLS1000_1, JLS1000_2, JLS2, JLS3, JLS4, JLS5, JLS6, JLW1, JLW1000_1, JLW1000_2, JLW2, JLW3, JLW4, JLW5, logo_ddr, logo_nvidia, logo_pci } from "../assets";

const product = {
  "Server" : {
    main_img : JLS1000_1,
    title : 'Powerful GPU Accelerator',
    product : 'JLS1000',
    company : [logo_nvidia, logo_pci, logo_ddr],
    feature_des : 'Powerful GPU Platform for High Performance Computing',
    feature : [
      {
        icon : JLS1,
        content : 'Modular architecture to provide customer the best performance platform',
      },
      {
        icon : JLS2,
        content : 'Best GPU-GPU Communication with Nvidia NVlink and NVswitch Architecture on H100/H800/B100 8 GPU HGX architecture'
      },
      {
        icon : JLS3,
        content : 'Latest DDR5 and PCIe gen 5.0 ready platform design'
      },
      {
        icon : JLS4,
        content : 'Optimized switchboard Provides maximum bandwidth for GPU direct RDMA'
      },
      {
        icon : JLS5,
        content : 'Increase power efficiency and reliability by decoupling 12V and 54V power source'
      },
      {
        icon : JLS6,
        content : 'Support liquid cooling system (optional)'
      },
    ],
    spec_img : JLS1000_2,
    spec : [
      {'Size' : '8U Rackmount, W x H x D: 448x 352x 850 mm'},
      {'GPU module' : 'NVIDIA HGX H100 8-GPU SXM 80GB 700W GPUs Assembly (Optional H800/H200/B100)'},
      {'CPU module' : 'Intel CPU,2.0GHZ,56C,350W,E-5,8480+,105MB,FCLGA,4677P'},
      {'Memory' : '32 x 64GB RDIMM, 4800MT/s Dual Rank'},
      {'Motherboard' : 'Intel EGS CPU up to 385W'},
      {'Storage - NVMe (M.2)' : 'SSD,PM9A3,M.2,NVME,1920GB'},
      {'Storage - NVMe (U.2)' : 'SSD,PM9A3,U.2,NVME,7.68TB'},
      {'Network Card - 400G' : 'AIC CARD,400G CX7,SINGLE-PORT,OSFP,PCIE5.0X16,IB'},
      {'Network Card - 200G' : 'AIC CARD,200G,DUAL-PORT,VPI PCIE5 MCX755106AS-HEAT'},
      {'OCP 3.0 Network' : 'OCP3.0 NIC 2X100G QSFP56,PCI-E4.0X16/CONNECTX-6'},
      {'Cooling' : 'Air cooling (Optional Liquid cooling)'},
      {'Fan' : '10x 8086 Fan for GPU cooling / 6x 6056 Fan for CPU cooling'},
      {'PSU' : '3300W 54V PSU, support 3+3 redundancy, 2400W 12V PSU, support 1+1 redundancy'},
      {'SW' : 'UEFI BIOS (Optional OS, SW Stack)'}
    ]
  },
  "Workstation" : {
    main_img : JLW1000_1,
    title : 'Powerful GPU Workstation',
    product : 'JLW1000',
    company : [logo_nvidia, logo_pci, logo_ddr],
    feature_des : 'Flexible Configuration and High Scalability',
    feature : [
      {
        icon : JLW1,
        content : 'Parallel GPU Design, Supports Flexible Assembly and Disassembly',
      },
      {
        icon : JLW2,
        content : 'Support NVLink'
      },
      {
        icon : JLW3,
        content : 'Support NVMe M.2 (2280 /22110) SSD'
      },
      {
        icon : JLW4,
        content : 'Support Multi USB 3.0 ,USB 2.0 Ports'
      },
      {
        icon : JLW5,
        content : 'Onboard Dual 10Gbps RJ45 Ethernet Ports, IPMI Management Port'
      },
    ],
    spec_img : JLW1000_2,
    spec : [
      {'GPU module' : 'NVIDIA 2x RTX Series (Optional RTX6000, RTX8000, L40S)'},
      {'CPU module' : '1 x AMD Ryzen Threadripper PRO 5975WX'},
      {'Memory' : '128GB DDR5 / 512GB DDR4'},
      {'Storage - NVMe (M.2)' : '1x 2TB (Optional 7.68/15.36TB)'},
      {'Storage - NVMe (U.2)' : 'Optional'},
      {'Network' : '1x 10GbE & 1x 2.5GbE RJ45 (onboard)'},
      {'Fan' : 'Fan for GPU cooling / Fan for CPU cooling'},
      {'SW' : 'UEFI BIOS (Optional OS, SW Stack)'}
    ]
  },
  "Cluster" : {
    main_img : JLC1000_1,
    title : 'Powerful HPC Supercomputing Cluster',
    product : 'JLC1000',
    company : [logo_nvidia],
    feature_des : 'Total Package Optimized for AI Learning',
    feature : [
      {
        icon : JLC1,
        content : 'All-in-One  Package : HPC Server + Cooling + Starage + Infiniband',
      },
      {
        icon : JLC2,
        content : 'High Efficient Liquid Cooling System'
      },
      {
        icon : JLC3,
        content : 'Low PUE : Efficient Power Consumption and Reduced Power Cost'
      },
      {
        icon : JLC4,
        content : 'Optimized RACK Design Enables Easy Management including Power Units'
      },
      {
        icon : JLC5,
        content : 'Makes Efficient Use of Data Center Space'
      },
    ],
    spec_img : JLC1000_2,
    spec : [
      {'Size' : 'RACK Size, W x H x D: 600 x 2300 x 1500 mm (about)'},
      {'GPU module' : '8U NVIDIA 8x H100 SXM GPU Server (Customized configuration)'},
      {'Infiniband Switch 400G' : '1 x NVIDIA QM9700 400G IB Switch'},
      {'Ethernet Switch 200G' : '1 x NVIDIA 200GbE Ethernet Switch'},
      {'Ethernet Switch 10G' : '10G Cisco Ethernet Switch64'},
      {'DDN Storage' : '1 x AI400X224 x 30.72TB NVMe600TB Usable'},
      {'RPU' : '4RU, 19” and 21” Compatible'},
      {'Cooling' : 'Air assisted Liquid Cooling'},
      {'Fan' : 'liquid / air flow rate, water / air temperature, pump speed, RDHx fan speed'},
      {'SW' : 'UEFI BIOS (Optional OS, SW Stack)'}
    ]
  }
}

export default product;