import {styled} from 'styled-components';
import { dropdown, exit_grey, logo_jikji, menu, upright, upright_g } from '../assets';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {useEffect, useRef, useState} from 'react';
import Contact from './Contact';

const S = {
  Nav : styled.nav`
    width : 100vw;
    height : 100px;

    position : sticky;
    top : 0;
    left : 0;
    z-index : 2;

    display : flex;
    justify-content : space-between;
    align-items : center;
    
    box-sizing : border-box;
    padding : 0 10vw;
    background : #ffffff;

    @media(max-width : 1024px) {
      display : none;
    }
  `,

  Logo : styled.img`
    width : 134px;
    height : 31px;
    cursor : pointer;

    &.exit {
      width : 20px;
      height : 20px;
    }

    &.grey {
      filter : grayscale(100%)
    }

    &.mobile {
      width : 80px;
      height : 20px;
    }
  `,

  Menu : styled.section`
    display : flex;
    align-items : center;
    width : 500px;
  `,
  Menu_item : styled(Link)`
    font-size : 20px;
    font-weight : 400;
    line-height : 20px;
    color : #8496b0;
    margin : 0 20px;

    cursor : pointer;
    text-decoration : none;

    display : flex;
    align-items : center;

    &.select {
      color : 4d617c;
      font-weight :800
    }

    & img {
      width : 10px;
      height : 10px;
      margin-left : 10px;
    }
  `,
  Menu_item_a : styled.a`
    font-size : 20px;
    font-weight : 400;
    line-height : 20px;
    color : #8496b0;
    margin : 0 20px;

    cursor : pointer;
    text-decoration : none;

    display : flex;
    align-items : center;

    &.select {
      color : 4d617c;
      font-weight :800
    }

    & img {
      width : 10px;
      height : 10px;
      margin-left : 10px;
    }
  `,
  Button : styled.button`
    width : 150px;
    height : 40px;
    background : #4788c8;
    color : #ffffff;
    border-radius : 50px;
    border : 0;

    font-size : 20px;
    font-weight : 400;
    line-height : 20px;

    margin-left : 20px;
    cursor : pointer;

    font-family : 'SUIT';
  `,
  M_Nav_sec : styled.section`
    width : 100vw;
    height : 100vh;
    background : #00000066;

    position : absolute;
    top : 0;
    left : 0;
  `,
  M_Nav : styled.nav`
    width : 100vw;
    height : 80px;

    position : sticky;
    top : 0;
    left : 0;
    z-index : 5;

    display : flex;
    justify-content : space-between;
    align-items : center;
    
    box-sizing : border-box;
    background : #ffffff;

    padding : 0 20px;

    @media(min-width : 1025px) {
      display : none;
    }
  `,
  Menu_icon : styled.img`
    width : 30px;
    height : 30px;

    @media(max-width : 1024px) {
      width : 20px;
      height : 20px;
    }
  `,

  Tab : styled.section`
    width : 100vw;
    height : 640px;
    background : linear-gradient(0deg, #ffffff 0%, #c5c5c5 400%);

    position : absolute;
    top : 0;
    left : 0;
  `,
  Tab_header : styled.section`
    height : 80px;
    display : flex;
    justify-content : space-between;
    align-items : center;
    padding : 25px 20px;
    box-sizing : border-box;
  `,
  Tab_menu : styled.div`
    width : 100%;
    height : 75px;

    font-size : 25px;
    line-height : 25px;
    font-weight : 800;
    text-align : start;

    padding : 25px 30px;
    box-sizing : border-box;
    border-bottom : 2px solid #eaeaea;

    display : flex;
    justify-content : space-between;

    font-family : 'SUIT';

    &.contact {
      color : #4788c8;

    }

    & img {
      &.open {
        transform : rotate(180deg);
      }
    }
  `,
  Tab_menu_a : styled.a`
    width : 100%;
    height : 75px;

    font-size : 25px;
    line-height : 25px;
    font-weight : 800;
    text-align : start;

    padding : 25px 30px;
    box-sizing : border-box;
    border-bottom : 2px solid #eaeaea;

    display : flex;
    justify-content : space-between;

    font-family : 'SUIT';
    text-decoration : none;
    color : #000000;

    &.contact {
      color : #4788c8;

    }

    & img {
      &.open {
        transform : rotate(180deg);
      }
    }
  `,
  Tab_submenu : styled.div`
    width : 100%;
    height : 200px;
    background : #ffffff;

    display : flex;
    flex-direction : column;
    justify-content : space-around;
  `,
  Tab_submenu_item : styled.div`
    width : 100%;
    font-size : 18px;
    color : #aaaaaa;
    text-align : start;
    padding-left : 30px;

    box-sizing : border-box;
  `,

}
const Nav = () => {

  const navigate = useNavigate();
  const [isopen, setIsopen] = useState(false); //contact modal
  const [istabopen, setIstabopen] = useState(false) // mobile menutab
  const [issubopen, setIssubopen] = useState(false) // mobile menutab
  
  const closeModal = () => {setIsopen(false)};

  const location = useLocation();

  const [url, setUrl] = useState('/')

  useEffect(() => {
    setUrl(location.pathname)
  }, [location])
  
  useEffect(() => {
    if (isopen || istabopen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isopen, istabopen])

  const [scroll, setScroll] = useState(0)
  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY);
    };

    // 스크롤 이벤트 리스너 등록
    window.addEventListener('scroll', handleScroll);

    // 클린업 함수
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const setbg = () => {
    if (location.pathname === '/tech') {
      let background = '#ffffff'
      const height = window.innerHeight;
      
      if (scroll / height < 1) {
        background = '#4788c8';
      } else if (scroll / height >= 2 && scroll / height < 3) {
        background = '#42bfda'
      } else {
        background = '#ffffff'
      }

      return {
        background
      }
    } 
  }

  const setwhite = () => {
    if(location.pathname === '/tech') {
      const height = window.innerHeight;
      if (scroll / height < 1 || (scroll / height >= 2 && scroll / height < 3)) {
        return {
          filter : 'brightness(0) invert(1)',
          color : '#ffffff'
        }
      } 
    }
  }
  
  const setreverse = () => {
    if(location.pathname === '/tech') {
      const height = window.innerHeight;
      if (scroll / height < 1) {
        return {
          background : '#ffffff',
          color : '#4788c8'
        }
      } else if(scroll / height >= 2 && scroll / height < 3) {
        return {
          background : '#ffffff',
          color : '#42bfda'
        }
      }
    }
  }


  const tabRef = useRef();
  const checkclick = (e) => {
    if (tabRef?.current && !tabRef?.current.contains(e.target)) {
      setIstabopen(!istabopen)
    }
  }

  return (
    <>
      <S.Nav style={setbg()}>
        <S.Logo style={setwhite()} src={logo_jikji} onClick={() => navigate('/')} alt='logo'/>

        <S.Menu>
          <S.Menu_item style={setwhite()} to='/tech' className={url === '/tech' && 'select'}>Technology</S.Menu_item>
          <S.Menu_item style={setwhite()} to='/product' className={url === '/product' && 'select'}>Product</S.Menu_item>
          <S.Menu_item_a style={setwhite()} href="https://n3n.ai" target='_blank'>N3N
            <img src={upright} alt="arrow"/> 
          </S.Menu_item_a>
          <S.Button style={setreverse()} onClick={() => setIsopen(!isopen)}>Contact Us</S.Button>
        </S.Menu>

      </S.Nav>
      {isopen && <Contact closeModal={closeModal}/>}

      <S.M_Nav>
        <span/>
        <S.Logo className='mobile' src={logo_jikji} onClick={() => navigate('/')} alt='jikji logo'/>
        <S.Menu_icon src={menu} onClick={() => setIstabopen(!istabopen)} alt='menu icon'/>

        {istabopen &&
          <S.M_Nav_sec onClick={(e) => checkclick(e)}>
            <S.Tab ref={tabRef}>
              <S.Tab_header>
                <span/>
                <S.Logo src={logo_jikji} className='grey mobile' onClick={() => navigate('/')} alt='jikji logo'/>
                <S.Logo src={exit_grey} className="exit" onClick={() => {setIstabopen(false); setIssubopen(false)}} alt='exit'/>
              </S.Tab_header>
              <S.Tab_menu onClick={() => {navigate('/tech'); setIstabopen(false)}}>Technology</S.Tab_menu>
              <S.Tab_menu onClick={() => setIssubopen(!issubopen)}>Product<img src={dropdown} alt="dropdown" className={!issubopen && 'open'} style={{'width' : '15px' }}/></S.Tab_menu>
              {issubopen &&
                <S.Tab_submenu>
                  <S.Tab_submenu_item onClick={() => {navigate('/product', {state : 'Server'}); setIstabopen(false)}}>Server</S.Tab_submenu_item>
                  <S.Tab_submenu_item onClick={() => {navigate('/product', {state : 'Workstation'}); setIstabopen(false)}}>Workstation</S.Tab_submenu_item>
                  <S.Tab_submenu_item onClick={() => {navigate('/product', {state : 'Cluster'}); setIstabopen(false)}}>Cluster</S.Tab_submenu_item>
                </S.Tab_submenu>
              }
              <S.Tab_menu_a href="https://n3n.ai" target='_blank'>N3N <img src={upright_g} alt="arrow" style={{'width' : '15px'}}/></S.Tab_menu_a>
              <S.Tab_menu className='contact' onClick={() => {setIsopen(!isopen); setIstabopen(false)}}>Contact Us</S.Tab_menu>
            </S.Tab>
          </S.M_Nav_sec>
        }
      </S.M_Nav>
    </>
  )
}

export default Nav;