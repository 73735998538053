import { LiteYoutubeEmbed } from "react-lite-yt-embed"; // 라이브러리 import
import Styled from "styled-components";

const VideoComp = ({id, idx}) => {
  return(
    <Video>
      <LiteYoutubeEmbed id={id} defaultPlay={idx===0 && true}/>
    </Video>  
  )
}

const Video = Styled.div`
  width : 70vw;
  height : auto;
  margin : 5vh auto;

  @media(max-width : 1024px) {
    width : 80vw;
    margin : 3vh auto;
  }
`

export default VideoComp;