import VideoComp from "../components/VideoComp";
import { useParams } from "react-router-dom";
import {styled} from 'styled-components';

const Video = () => {

  const videolist = [
    {id : "5zCf2K2FHZg", tag : "solutions", title : "GPU-Centric Micro Data Center"},
    {id : "lQ8yNOU7QLE", tag : "solutions", title : "SDDC Architecture"},
    {id : "jaave6WPNSM", tag : "solutions", title : "DC Engineering"},
    {id : "ajFUEcGqW1I", tag : "solutions", title : ""},
    {id : "BRanhfEBAJA", tag : "solutions", title : "GPU Cloud Service"},
    {id : "KFLbf91JT8w", tag : "sys-computing", title : "직지랩스 생각 : COMPUTING"},
    {id : "E85Orf9lGCQ", tag : "sys-computing", title : ""},
    {id : "yhvslEV3bKA", tag : "sys-computing", title : ""},
    {id : "h_jda3knxdE", tag : "sys-network", title : "직지랩스 생각 : NETWORK"},
    {id : "isyA_Q8fSz8", tag : "sys-storage", title : "직지랩스 생각 : STORAGE"},
    {id : "BuWHdE6G_Tw", tag : "sys-cooling", title : "직지랩스 생각 : COOLING"},
    {id : "ZpjynL3I9-o", tag : "sys-power", title : "직지랩스 생각 : POWER"},
  ]

  const param = useParams().id;

  return (
    <S.Section>
      {param === 'solutions' && <S.Solution>직지랩스 SOLUTIONS</S.Solution>}
      <S.Video>
      {videolist.filter(item => item.tag === param).map((item, key) => (
        <div>
          <S.Title className="title">{item.title}</S.Title>
          <VideoComp id={item.id} key={key} idx={key} />
        </div>
      ))}
      </S.Video>

    </S.Section>
  )
}

const S = {
  Section : styled.section`
    min-height : calc(100vh - 387px);

    @media(min-width : 1025px) {
      min-height : calc(100vh - 297px);
    }

  `,
  Solution : styled.h3`
    font-size : 40px;
    font-weight : 900;

    @media(max-width : 1024px) {
      font-size : 30px;
    }
  `,
  Video : styled.section`
    
    :first-child > .title {
      margin : 0 auto;
    }

  `,
  Title : styled.div`
    width : 70vw;
    margin : 150px auto 0;
    font-size : 32px;
    font-weight : 700;
    text-align : start;

    @media(max-width : 1024px) {
      width : 80vw;
      font-size : 20px;
      margin : 50px auto 0;
    }
  `
}

export default Video;